input:focus-visible {
  outline: none !important;
}

a:focus-visible {
  outline: none !important;
}

.css-t3ipsp-control:hover {
  border: none !important;
}

.text-red-500 {
  color: red;
}

div:focus {
  text-decoration: none !important;
}

a:hover {
  text-decoration: none !important;
}

.file-format {
  display: flex;
}

.tailwind {
  margin-right: 10px;
}

.nothing-leaks {
  height: 50vh;
}



.nothing-leaks h6 {
  font-size: 32px;
  text-align: center;
  margin-top: 50px;
}

.koconnected-img {
  /* padding: .5rem 0 .5rem 1.5rem; */
  width: 50px;
}

.file-fix-edit-name {
  margin-top: 20px !important;
}

.video-play-icon {
  font-size: 25px;
  margin-right: 10px;
}

.video-play-icon1 {
  font-size: 22px;
  /* margin-right: 10px; */
}

.category-display-div {
  display: flex;
}

.main-background {
  /* margin: 0; */
  padding: 100px 0px;
  /* height: 60vh; */
  background: linear-gradient(to bottom,
      #EFF8FF 0%,
      #EFF8FF 40%,
      #F1F9FF 40%,
      #F1F9FF 60%,
      #F2F9FF 60%,
      #F2F9FF 80%,
      #F5FAFF 80%,
      #F5FAFF 90%,
      #F8FCFF 90%,
      #F8FCFF 100%),
    linear-gradient(to right,
      #F8FCFF 0%,
      #F8FCFF 30%,
      #F8FCFF 100%);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.main-background1 {
  margin: 0;
  padding: 0;
  height: 100vh;
  background: linear-gradient(to bottom,
      #EFF8FF 0%,
      #EFF8FF 40%,
      #F1F9FF 40%,
      #F1F9FF 60%,
      #F2F9FF 60%,
      #F2F9FF 80%,
      #F5FAFF 80%,
      #F5FAFF 90%,
      #F8FCFF 90%,
      #F8FCFF 100%),
    linear-gradient(to right,
      #F8FCFF 0%,
      #F8FCFF 30%,
      #F8FCFF 100%);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

@font-face {
  font-family: 'Haffer XH';
  src: local('Haffer XH'),
    url(./fonts/H4FF3RXH/OTF/HafferXH-Bold.otf) format('opentype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Haffer XH';
  src: local('Haffer XH'),
    url(./fonts/H4FF3RXH/OTF/HafferXH-Heavy.otf) format('opentype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Haffer XH';
  src: local('Haffer XH'),
    url(./fonts/H4FF3RXH/OTF/HafferXH-Light.otf) format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Haffer XH';
  src: local('Haffer XH'),
    url(./fonts/H4FF3RXH/OTF/HafferXH-Medium.otf) format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Haffer XH';
  src: local('Haffer XH'),
    url(./fonts/H4FF3RXH/OTF/HafferXH-Regular.otf) format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Haffer XH';
  src: local('Haffer XH'),
    url(./fonts/H4FF3RXH/OTF/HafferXH-SemiBold.otf) format('opentype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Haffer XH';
  src: local('Haffer XH'),
    url(./fonts/H4FF3RXH/OTF/HafferXH-Thin.otf) format('opentype');
  font-weight: 200;
  font-style: normal
}

.unique-container {
  max-width: 540px;
  margin: auto;
}

.cards-of-cards-div {
  display: flex;
}

.a-unique-source {
  text-align: center;
  font-weight: 600;
  font-size: 64px;
  line-height: 73.6px;
  letter-spacing: -1.5px;
  color: #001D53;
}

.school-impact-img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.school-impact {
  font-size: 16px;
  color: #50607B;
  font-weight: 400;
  letter-spacing: 0.2px;
}

.im-btn-div {
  text-align: center;
}

.existing-attachments {
  font-size: 18px;
}

.filename-attachment {
  color: #000000 !important;
  margin-top: 30px !important;
}

.im-journalist-btn {
  border: 1px solid #0C78F1;
  padding: 12px 30px;
  background-color: transparent;
  border-radius: 20px;
  color: #0C78F1;
  margin-right: 10px;
  font-size: 16px;
  font-weight: 500;
}

.im-source-btn {
  border: none;
  background-color: #0C78F1;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  padding: 12px 30px;
  border-radius: 20px;
}

.im-source-btn a {
  color: #ffffff;
}

.our-process {
  text-align: start;
  /* margin-top: 100px; */
  font-size: 16px;
  color: #299F00;
  font-weight: 500;
  line-height: 22px;
  text-transform: uppercase;
}

.our-process-sub {
  max-width: 540px;
  margin: auto;
}

.our-process-sub h6 {
  text-align: start;
  font-size: 32px;
  font-weight: 600;
  color: #1F1D1D;
  line-height: 42px;
  letter-spacing: -1px;
  max-width: 650px;
}

.d-flex-final {
  display: flex;
  justify-content: space-between;
}

.school-impact-big-img {
  width: 540px;
}

.col-new-part {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.col-new-part p {
  font-size: 16px;
  max-width: 520px;
  color: #656B76;
}

.about-time {
  font-size: 32px;
  font-weight: 600;
  line-height: 41.6px;
  letter-spacing: -1px;

}

.coverage-img-div {
  border: 1px solid #E9EDF2;
  border-radius: 16px;
  padding: 50px 70px;
}

.margin-top-number {
  margin-top: 120px;
}

.join-our-community {
  border-radius: 20px;
  border: 1.5px solid #0c78f1;
  width: 300px;
  padding: 8px 0px;
  color: #0c78f1;
  background-color: transparent;
  font-weight: 600;
  cursor: pointer;
}

.leaks-div {
  display: flex;
  justify-content: space-between;
}

.our-process-sub p {
  font-size: 16px;
  text-align: center;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: #50607B;
}

.we-connect {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 30px;
  font-size: 16px;
  color: #50607B;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 24px;
}

.navbar-bcg {
  background-color: #ffffff;
  box-shadow: 4px 4px 32px 0px #2447710F;
  padding-top: 20px !important;
  padding-bottom: 20px !important;

}

.nav-link {
  color: #1F1D1D !important;
  font-weight: 500;
  line-height: 19.2px;
  font-size: 16px;
}

.profile-logo1 {
  width: 120px;
  margin-right: 35px;
}

.search-div {
  border: none;
}

.create-a-leak {
  background-color: #007bff;
  color: #ffffff;
  border-radius: 30px;
  border: none;
  padding: 15px 25px;
}

.my-leak-text {
  font-size: 22px;
  color: #64748B;
  font-weight: 500;
  line-height: 28px;

}

.the-leak-parent-div {
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 4px 8px 32px 0px #2447710F;
  padding: 50px;
  margin-top: 50px;
  border: 1px solid #007bff;
}

.the-leak-header-text {
  font-size: 24px;
  font-weight: 600;
  line-height: 34.8px;
  color: #1F1D1D;
  max-width: 1032;
}

.the-leak-header-subtext {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #64748B;
  /* max-width: 500px !important; */
  overflow: break-word !important;
}

.toggle-left-container {
  display: flex;
  justify-content: space-between;
}

.cat-new-options-div {
  display: flex;
}

.cat-new-options {
  background-color: #F6F6F6;
  color: #50607B;
  padding: 10px 15px;
  margin-top: 10px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 400;
  margin-right: 10px;
}



.categories-text {
  font-size: 18px;
  font-weight: 500;
  line-height: 23.4px;
  columns: #000000;
  margin-top: 30px;
}

.source-upload-file {
  border: 1px solid #E9EDF2;
  padding: 50px;
  border-radius: 30px;
  margin-bottom: 30px;
}

.file-name-kb {
  margin-left: 15px;
  font-size: 14px;
  color: #334155;
  margin-bottom: 5px;
}

.file-size-kb {
  margin-left: 15px;
  font-size: 12px;
  color: #334155;
  margin-bottom: 10px;
}

.parent-who {
  border-radius: 30px;
  border: 1px solid #ced4da;
}

.parent-who .css-13cymwt-control {
  border: none;
}

.parent-who .css-t3ipsp-control {
  border-color: none !important;
  box-shadow: none !important;
  outline: none !important;
}

.search-input {
  border: none !important;
  font-size: 14px;
  line-height: 19.2px;
  color: #1F1D1D;
  margin-left: 5px;
  letter-spacing: -1%;
  width: 70px;
}

select.query-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url('./assets/arrow-down.svg');
  /* Custom dropdown icon */
  /* Custom dropdown icon */
  background-repeat: no-repeat;
  background-position: right 25px center;
  /* Adjust position */
  padding-right: 30px;
  /* Space for the custom icon */
  cursor: pointer;
}

.search-btn-signup {
  border: 2px solid #0C78F1;
  border-radius: 25px;
  background-color: transparent;
  font-size: 16px;
  padding: 12px 30px;
  color: #0C78F1;
  font-weight: 700;
  letter-spacing: -0.2px;
  line-height: 19.2px;
}

.footer-bcg {
  background-color: #020B1A;
  padding: 50px 0px;
  margin-top: 100px;
}

.footer-div {
  display: flex;
  justify-content: space-between;
}

.copy-right-text {
  color: #ffffff;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: -0.2px;
  line-height: 23.4px;
}

.policy-container {
  display: flex;
}

.policy-text {
  color: #ffffff;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: -0.2px;
  line-height: 23.4px;
  margin-right: 15px;
}

.terms-text {
  color: #ffffff;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: -0.2px;
  line-height: 23.4px;
}

.sign-up-form {
  background-color: #FFFFFF;
  padding: 28px 28px 28px 28px;
  width: 40%;
  margin: auto;
  margin-top: 50px;
  border-radius: 10px;
  border: 1px solid #E9EDF2;
  box-shadow: 4px 8px 16px 0px #2447710F;
}

.signup-container {
  text-align: center;
}

.logo-img {
  margin-top: 5%;
  width: 150px;
}

.sign-up-form-text {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  color: #101928;
  margin-bottom: 35px;
}

.signup-label {
  font-size: 14px;
  font-weight: 400;
  line-height: 20.3px;
  color: #101928;
}

.sign-up-input {
  padding: 16px 20px 16px 20px !important;
  border: 1px solid #E9EDF2;
  margin-bottom: 20px;
  border-radius: 30px !important;
  width: 100%;
  margin-top: 5px;
  font-size: 14px;
  color: #98A2B3;
  line-height: 20.3px;
  letter-spacing: 0.3px;
  width: 100%;
  display: block;
}

.sign-up-btn {
  width: 100%;
  background-color: #0C78F1;
  border-radius: 30px;
  color: #FFFFFF;
  font-size: 16px;
  border: none;
  margin-top: 30px;
  padding: 12px 24px 12px 24px;
}

.sign-up-form-text-sub-small {
  font-size: 14px;
  color: #98A2B3;
  line-height: 20.3px;
  text-align: center;
  margin-bottom: 35px;
  margin-top: 25px;
}

.sign-up-form-text-sub-small-login {
  font-size: 14px;
  color: #5A5F67;
  line-height: 20.3px;
  text-align: center;
  margin-bottom: 35px;
  margin-top: 25px;
  text-decoration: none;
}

.sign-up-form-text1 {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  color: #101928;
}

.sign-up-form-text-sub {
  font-size: 16px;
  color: #667185;
  line-height: 23.2px;
  text-align: center;
  margin-bottom: 35px;
}

.reset-password {
  display: flex !important;
  justify-content: space-between;
}

.terms-text {
  /* text-decoration: none; */
  color: #0C78F1;
  font-size: 18px;
  font-weight: 400;
  line-height: 20.3px;
}

.verify-heading {
  margin-top: 40px;
  font-size: 48px;
  color: #001D53;
  letter-spacing: -0.4px;
  font-weight: 600;
  text-align: center;
}

.verify-heading-sub {
  font-size: 18px;
  color: #5A5F67;
  letter-spacing: 0.2px;
  font-weight: 400;
  max-width: 431px;
  margin: auto;
  text-align: center;
}

.has-pwd-icon {
  position: relative;
}

.view-pwd {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 15px;
  color: #8a8383;
  cursor: pointer;
}

.the-signs .css-13cymwt-control {
  padding: 10px 20px 10px 20px !important;
  border: 1px solid #E9EDF2;
  margin-bottom: 20px;
  border-radius: 30px !important;
  width: 100%;
  margin-top: 5px;
  font-size: 14px;
  color: #98A2B3;
  line-height: 20.3px;
  letter-spacing: 0.3px;
  width: 100%;
  /* display: block; */
  /* height: 200px; */
}


.css-t3ipsp-control {
  font-size: 14px !important;
  color: #1F1D1D !important;
  font-weight: 400;
  border-radius: 30px !important;
  padding: 9px 20px 9px 20px !important;
  text-transform: capitalize !important;
}

.save-and-continue-contain {
  width: 300px;
  margin: auto;
}

.save-and-continue {
  width: 100%;
  background-color: #0C78F1;
  border-radius: 30px;
  color: #FFFFFF;
  font-size: 16px;
  border: none;
  margin-top: 30px;
  padding: 16px 24px 16px 24px;

}

.nav-linking {
  border-bottom: 2px solid #EAEBF0 !important;
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
  color: #A8B3C7 !important;
  font-size: 16px;
  padding: 12px 12px 14px 12px;
  text-decoration: none;
}

.nav-tabsub {
  /* border-bottom: 2px solid #EAEBF0 !important; */
  padding: 10px;
}

.nav-linking:hover {
  border-bottom: 2px solid #437EF7 !important;
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
  color: #437EF7 !important;
  text-decoration: none;
}

a:focus-visible {
  outline: none;
}

.nav-link:focus {
  border-left: transparent !important;
  border-right: transparent !important;
  border-top: transparent !important;
}

.nav-iteming .nav-linking {
  border-left: transparent !important;
  border-right: transparent !important;
  border-top: transparent !important;
}

.no-result-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 50vh; */
  /* Optionally, adjust the height of the container */
}

.custom-query-table {
  margin-top: 50px;
}

.nav-iteming .active {
  border-bottom: 2px solid #437EF7 !important;
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
  color: #437EF7 !important;
}

.my-queries-sub {
  color: #50607B;
  font-weight: 500;
  font-size: 22px;
  line-height: 28.6px;
  margin-top: 50px;
  margin-bottom: 30px;
}



.for-flex {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
}

.dropdown-menu {
  margin-top: 35px !important;
  margin-left: -200px !important;
  padding: 20px !important;
  box-shadow: 8px 12px 24px 0px #2447710F !important;
  border: 1px solid #F3F3F3 !important;
  width: 300px !important;
}

.dropdown-container-profile {
  text-align: center;
}

.dropdown-container-profile-name {
  font-size: 18px;
  color: #001D53;
  margin-top: 10px;
}

.the-background-palace {
  border: 1px solid #E9EDF2;
  border-radius: 20px;
  box-shadow: 4px 8px 32px 0px #2447710F;
  padding: 50px 50px;
  margin-bottom: 50px;
}

.the-background-log {
  background-color: #F9F9F9;
  border-radius: 30px;
  padding: 15px 30px;
  display: flex;
  margin-bottom: 30px;
}

.input-point {
  border: none;
  background-color: #F9F9F9;
  padding-left: 10px;
  width: 100%;
}

.query-container-div-main {
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
  border-bottom: 1px solid #E9EDF2;
  padding-bottom: 20px;
}

.color-div {
  display: flex;
}

.color-7-text {
  margin-left: 20px;
  max-width: 400px;
  font-size: 18px;
  line-height: 25.2px;
  font-weight: 500;
  color: #1F1D1D;
}

.color-7-text:hover {
  margin-left: 20px;
  max-width: 400px;
  font-size: 18px;
  line-height: 25.2px;
  font-weight: 500;
  color: #1F1D1D;
}

.briefcase {
  margin-left: 20px;
  color: #0C78F1;
  margin-bottom: 10px;
}

.avatar-one {
  vertical-align: top !important;
  display: flex;
  flex-direction: column;
}

.posted-main {
  margin-left: 10px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.8px;
  color: #979DA8;
}

.posted-main-span {
  margin-left: 10px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.8px;
  color: #C93A3A;
}

/* .placeholder-img {
  display: flex;
  flex-direction: column;
  justify-content: start;
} */
.swal2-title {
  font-size: 20px;
  color: #000000;
}

.swal2-confirm {
  background-color: #0C78F1 !important;
}

/* .swal2-validation-message {
  display: block;
} */


/* .swal2-popup {
  display: none !important;
} */

.profile-person-flex-bg {
  background-color: #F8FBFF;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
  /* max-width: 310px !important; */
}

.profile-person-flex {
  display: flex;
  margin-bottom: 10px;
}

.profile-person-flex a {
  font-size: 14px;
  color: #1F1D1D;
  font-weight: 400;
  padding-left: 3px !important;
  width: 310px !important;
  white-space: normal;
}

.extended-div-container {
  display: flex;
  justify-content: space-between;
}

.query-details-container {
  margin-top: 50px;
  padding: 50px 50px;
  border-radius: 20px;
  box-shadow: 4px 8px 32px 0px #2447710F;
}

.extended-admin-text {
  max-width: 636px;
  color: #1F1D1D;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 0px;

}

.extended-admin {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.extended-mainline {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.person-detail {
  display: flex;
  margin-top: 50px;
}

.extended-img-person {
  font-size: 16px;
  margin-right: 5px;
  border-radius: 50px;
  width: 80px;
}

.person-image {
  font-size: 16px;
  font-weight: 500;
  line-height: 20.8px;
  color: #50607B;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
  margin-bottom: 0px;
}

.status-status {
  margin-top: 50px;
  color: #000000;
  font-size: 18px;
  font-weight: 500;
  line-height: 23.4px;
}

select.form-control:not([size]):not([multiple]) {
  height: auto !important;
}

.awaiting-text {
  /* margin-left: 5px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 0px;
  color: #049A37;
  font-size: 16px;
  font-weight: 500;
  line-height: 20.8px;
  text-transform: capitalize;
}

.query-description {
  font-size: 18px;
  color: #000000;
  font-weight: 500;
  line-height: 23.4px;
  margin-top: 50px;
}

.query-sub-description {
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  color: #64748B;
  /* margin-top: 20px; */
  /* white-space: pre-wrap; */
  overflow-wrap: break-word;
  /* Allow text to break between characters */
}

.leak-extra {
  color: #007bff;
  margin-left: 10px;
  font-size: 25px;
}

.spread-them {
  display: flex;
  margin-top: 20px;
}

.spread-them p {
  background-color: #F6F6F6;
  /* margin-left: 25px; */
  padding: 10px;
  border-radius: 30px;
}

.extended-line {
  display: flex !important;
}

.notice {
  overflow-wrap: break-word;
}

.pitch-bcg {
  border-radius: 30px;
  padding: 12px 40px 12px 40px;
  border: none;
  color: #ffffff;
  background-color: #0C78F1;

}

.modal {
  margin-top: 50px;
  height: 800px;
}

.app-modal-content {
  border-radius: 10px;
  padding: 30px 30px;
  background: #fff;
  border: none;
  margin: 0 auto;
}

.modal-header {
  border-bottom: none;
  font-style: normal;
  font-weight: bold;
  font-size: 2rem;
  line-height: 2.4rem;
  color: var(--primary-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-inline: 0 !important;
  border-bottom: 2px solid var(--light-gray);
}

.modal-header::before,
.modal-header::after {
  display: none;
}

.modal-header h4.modal-title {
  font-size: 16px;
  color: #0C78F1;
  font-weight: 600;
  letter-spacing: 0.2px;
  padding-left: 12px;
  /* margin-bottom: 20px; */
}

.modal-header .close {
  padding: 10px;
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
}

.rec-modal-body {
  padding: 0;
}

.modal-xlg {
  width: 1200px !important;
  max-width: 1200px;
}

.modal-lg {
  width: 900px !important;
  max-width: 900px;
}

.modal-normal {
  width: 750px !important;
  max-width: 900px;
}

.modal-xsm {
  width: 400px !important;
  max-width: 400px;
}

.modal-xsm .modal-title {
  font-size: 0.5rem;
}

.modal-subtitle {
  margin-bottom: 30px;
  margin-top: -20px;
  font-size: 14px;
  font-family: inherit;
  color: #5e6c85;
}

.modal-dialog {
  max-width: 90vw;
  max-height: 100%;
}

.ReactModal__Overlay--after-open {
  z-index: 1000;
  background: rgba(0, 0, 0, 0.7) !important;
}

.ReactModal__Content--after-open {
  border: none !important;
  background: transparent !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #ffffff !important;
  border-radius: 10px !important;
}

.query-modal-div {
  font-size: 14px !important;
  color: #1F1D1D !important;
  font-weight: 400;
  border: 1px solid #E9EDF2;
  border-radius: 30px !important;
  padding: 16px 20px 16px 20px !important;
  display: flex;
  background-color: #F9F9F9 !important;
  text-transform: capitalize !important;
}

.active .nav-link {
  color: #007bff !important;
}

.my-inputing {
  border: none !important;
  background-color: #F9F9F9 !important;
  padding: 0px !important;
  font-size: 14px !important;
  ;
}

.my-inputing:focus-visible {
  outline: none;
}

.query-label {
  font-size: 14px !important;
  color: #1F1D1D !important;
  margin-top: 30px;
}

.query-input {
  font-size: 14px !important;
  color: #1F1D1D !important;
  font-weight: 400;
  border-radius: 30px !important;
  padding: 16px 20px 16px 20px !important;
  /* text-transform: capitalize !important; */
}

.query-input-description {
  border-radius: 30px;
  padding: 25px;
  height: 200px !important;
}

.query-input1 {
  font-size: 14px !important;
  color: #1F1D1D !important;
  font-weight: 400;
  border-radius: 30px !important;
  padding: 16px 20px 16px 20px !important;
  height: 150px;
  /* text-transform: capitalize !important; */
}

.create-query-div {
  text-align: end;
  margin-top: 20px;
}

.create-query-btn {
  /* width: 100%; */
  background-color: #0C78F1;
  border-radius: 8px;
  color: #FFFFFF;
  font-size: 16px;
  border: none;
  /* margin-top: 30px; */
  padding: 12px 24px 12px 24px;
  /* width: 100%; */
}


.data-header {
  margin-top: 50px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.2px;
  line-height: 22px;
  color: #64748B;
}

.verified-barge {
  font-size: 25px;
  color: #0d6efd;
}

.we-keep-header {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 22px;
  color: #64748B;
}



.profile-image-dropzone {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dropzone-container {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  /* border: 2px dashed #ccc; */
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  margin-bottom: 10px;
}

.profile-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.change-button {
  background-color: transparent;
  border-radius: 20px;
  color: #0C78F1;
  border: 1px solid #0C78F1;
  padding: 8px 15px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
}

.change-button:hover {
  background-color: #0C78F1;
  color: #ffffff;
}

.job-title-header {
  font-size: 16px !important;
  color: #001D53;
  font-weight: 500;
  line-height: 20.8px;
}

.unfinished-input {
  padding: 16px 20px !important;
  border-radius: 20px !important;
  border: 1px solid #E9EDF2 !important;
  color: #1F1D1D;
  font-size: 14px !important;
}

.show-public-profile p {
  margin-bottom: 0px;
  margin-left: 10px;
  margin-right: 7px;
  color: #667185;
  font-size: 14px;
  line-height: 20.3px;
}

.account-container1 {
  padding: 50px;
  background-color: #ffffff;
  box-shadow: 4px 8px 32px 0px #2447710F;
  border-radius: 20px;
  margin-bottom: 50px;
}

.profile-search-div {
  background-color: #F9F9F9;
  border-radius: 30px;
  color: #656B76;
  font-size: 16px;
  line-height: 16.2px;
  font-weight: 400;
  border: none;
  padding: 10px 20px;
}



.profile-search-div input {
  border: none !important;
  background-color: #F9F9F9;
  padding: 10px;
  width: 80%;
}


.no-result-yet-container {
  /* display: flex; */
  justify-content: center;
  align-items: center;
  height: 30vh;
  text-align: center;
  margin-top: 70px;
}

.no-result-yet {
  text-align: center;
}

.the-found-result {
  border: 3px solid #f5f5f5;
  border-radius: 20px;
  padding: 20px;
  margin-top: 50px;
}

.image-search-div {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  /* border: 2px dashed #ccc; */
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  margin: auto;
  margin-bottom: 20px;
}

.image-search {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.first_last {
  font-size: 16px;
  font-weight: 400;
  text-align: center;
}

.first_last1 {
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  /* text-transform: capitalize; */
}

.notify-past {
  font-size: 20px;
  font-weight: 500;
  color: #50607B;
  line-height: 28.6px;
  margin-bottom: 20px;
}

.learn-more-container {
  background-color: #F6FAFF;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.learn-more-container1 {
  background-color: #FFFFFF;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  box-shadow: 4px 8px 32px 0px #2447710F;
  border: 1px solid #1F1D1D;
}

.learn-more-text {
  font-size: 16px;
  color: #50607B;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 25.2px;
  max-width: 800px;
  margin-bottom: 0px;
}

.learn-more-text33 {
  font-size: 10px;
  color: #50607B;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 25.2px;
  max-width: 800px;
  margin-bottom: 0px;
  margin-top: 0px;
}

.learn-more-link {
  /* margin-top: 20px; */
  color: #001D53;
  font-size: 14px;
  font-weight: 500;
  line-height: 17.6px;
  margin-bottom: 0px;
}























.css-b62m3t-container .css-13cymwt-control {
  font-size: 14px !important;
  color: #1F1D1D !important;
  font-weight: 400;
  border-radius: 30px !important;
  padding: 9px 20px 9px 20px !important;
  text-transform: capitalize !important;
}

.profile-logo {
  width: 100px;
  margin-right: 35px;
}

.my-topics-text {
  color: #50607B;
  font-size: 18px;
  font-weight: 500;
  line-height: 23.4px;
  margin-top: 100px;
}

.sign-up-input-textarea-new {
  padding: 16px 20px 16px 20px !important;
  border: 1px solid #E9EDF2;
  margin-bottom: 20px;
  border-radius: 30px !important;
  margin-top: 5px;
  font-size: 14px;
  color: #98A2B3;
  line-height: 20.3px;
  letter-spacing: 0.3px;
  width: 100%;
  display: block;
  height: 200px;
}

.profile-person-flex-bg1 {
  background-color: #F8FBFF;
  padding: 20px 10px;
  border-radius: 10px;
  margin-bottom: 20px;
  /* max-width: 310px !important; */
}




.sign-up-btn {
  @apply py-[1.1rem] px-[2.7rem] bg-primary text-white font-medium rounded-lg text-[1.4rem];
}

.sign-up-btn:disabled,
.create-query-btn:disabled {
  position: relative;
  color: transparent !important;
}

.sign-up-btn:disabled::before,
.create-query-btn:disabled::before {
  content: "" !important;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 2px solid;
  border-top-color: white;
  -webkit-animation: loader 0.8s linear infinite;
  animation: loader 0.8s linear infinite;
}




@-webkit-keyframes loader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.paul-name {
  font-size: 16px;
  font-weight: 500;
  line-height: 20.8px;
  color: #50607B;
  margin-left: 10px;
  margin-bottom: 0px;
}

.paul-subnamedate {
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  color: #64748B;
  margin-left: 0px;
  margin-bottom: 0px;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

.leak-reply-name {
  margin-bottom: 0px;
  font-size: 16px;
  font-weight: 800;
}

.leak-reply-subname {
  margin-bottom: 0px;
  font-size: 14px;
  font-weight: 400;
  overflow-wrap: break-word !important;
  line-height: 22px;
  white-space: normal !important;
  overflow-wrap: break-word;
  max-width: 900px;
}

.leak-replies {
  margin-bottom: 20px;
  display: flex;
}

.paul-subname {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: #64748B;
  margin-left: 10px;
  margin-bottom: 0px;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

.paul-subname1 {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: #64748B;
  margin-bottom: 0px;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  margin-top: 20px;
}

.reply {
  margin-top: 20px;
  color: red;
  font-size: 16px;
  line-height: 19.2px;
  font-weight: 500;
  cursor: pointer;
}

.response-div {
  display: flex;
}

.response-div img {
  border-radius: 50px;
  width: 78px;
  height: 78px;
}

.response-div1 {
  display: flex;
}

.response-div1 img {
  border-radius: 50px;
  width: 78px;
  height: 78px;
}

.input-comment-text-div {
  border-top: 1px solid #E9EDF2;
  border-bottom: 1px solid #E9EDF2;
  display: flex;
  justify-content: space-between;
  padding: 10px 0px;
  margin-top: 10px;
}

.input-comment-text {
  border: none;
  width: 100%;
}

.submit-reply {
  background-color: #0C78F1;
  color: #ffffff;
  border-radius: 30px;
  border: none;
  padding: 15px 35px;
  font-size: 18px;
}

.profile-person-img {
  width: 48px;
  height: 48px;
  border-radius: 50px;
}

.person-profile-display {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.person-container {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  /* border: 2px dashed #ccc; */
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  margin-bottom: 10px;
  margin: auto;
}

.paul-subnames {
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  color: #64748B;
  margin-bottom: 0px;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

.paul-subname {
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  color: #64748B;
  margin-left: 10px;
  margin-bottom: 0px;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

/* Custom styles for ReactPaginate */
.pagination {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  justify-content: flex-end;
  /* Float to the right */
  margin-top: 35px;
  margin-bottom: 35px;
}

.pagination li {
  margin-left: 5px;
  margin-right: 5px;
}

.previous-class,
.next-class {
  margin-left: 10px;
  /* Add space between previous/next and page numbers */
  cursor: pointer;
  color: black;
  /* Default color */
}

.page-number {
  margin-left: 5px;
  /* Add space between pagination numbers */
  cursor: pointer;
  color: black;
  /* Default color */
}

.pagination li a {
  cursor: pointer;
}





.query-details-container1 {
  margin-top: 40px;
  padding: 40px 50px;
  border-radius: 20px;
  box-shadow: 4px 8px 32px 0px #2447710F;
  /* border: 1px solid red; */
}

/* .row {
  --bs-gutter-x: 1.5rem !important;
  --bs-gutter-y: 0 !important;
  display: flex !important;
  flex-wrap: wrap !important;
  margin-top: calc(-1* var(--bs-gutter-y)) !important;
  margin-right: calc(-0.5* var(--bs-gutter-x)) !important;
  margin-left: calc(-0.5* var(--bs-gutter-x)) !important;
} */


















h1 {
  font-family: "Haffer XH" !important;
}

h2 {
  font-family: "Haffer XH" !important;
}

h3 {
  font-family: "Haffer XH" !important;
}

h4 {
  font-family: "Haffer XH" !important;
}

h5 {
  font-family: "Haffer XH" !important;
}

p {
  font-family: "Haffer XH" !important;
}

span {
  font-family: "Haffer XH" !important;
}

a {
  font-family: "Haffer XH" !important;
}

label {
  font-family: "Haffer XH" !important;
}

div {
  font-family: "Haffer XH" !important;
}

button {
  font-family: "Haffer XH" !important;
}

input {
  font-family: "Haffer XH" !important;
}

.active-paginate {
  background-color: #007bff;
  /* Background color for active page */
  padding: 0 7px;
  border-radius: 0px;
  /* Text color for active page */
}

.active-paginate a {

  color: white !important;

}

.next-class a {
  font-weight: 700 !important;
}

.previous-class a {
  font-weight: 700 !important;
}

.navbar-toggler {
  border: 1px solid black
}

.search-div {
  display: none;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #000000;
  background-color: transparent;
}

.extended-line img {
  display: none;
}

@media only screen and (max-width: 995px) {

  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba(33, 37, 41, 0.75)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 100%;
    display: inline-block;
    height: 1.5em;
    vertical-align: middle;
    width: 1.5em;
  }

  .navbar-toggler {
    border: none !important;
    padding: none !important;
  }
}

@media only screen and (max-width: 480px) {
  /* .main-background {
    padding: 50px;
  } */

  .school-impact-big-img {
    width: 100%;
  }

  .coverage-img-div img {
    width: 100%;
  }

  .d-flex-final {
    display: block;
  }

  .leak-extra {
    color: #007bff;
    margin-left: 5px;
    font-size: 20px;
  }

  .my-leak-text {
    font-size: 18px;
    color: #64748B;
    font-weight: 500;
    line-height: 28px;
  }

  .categories-text {
    font-size: 16px;
    font-weight: 500;
    line-height: 23.4px;
    columns: #000000;
    margin-top: 20px;
  }

  .leaks-div {
    display: block;
  }

  .create-a-leak {
    width: 100%;
    margin-top: 20px;
  }

  .the-leak-parent-div {
    padding: 20px;
  }

  .color-7-text {
    font-size: 16px;
    max-width: 200px;
  }

  .color-7-text:hover {
    font-size: 16px;
    margin-left: 0px
  }

  .the-leak-header-text {
    font-size: 18px;
  }

  .query-container-div-main {
    display: flex;
    margin-top: 0px;
    margin-bottom: 20px;
  }

  .response-div img {
    width: 80px;
    height: 80px;
    margin-bottom: 15px;
    border-radius: 50px;
  }

  .response-div1 img {
    width: 80px;
    height: 80px;
    margin-bottom: 15px;
    border-radius: 50px;
  }

  .color-div {
    display: block;
    margin-bottom: 10px;
  }

  .book-this-mark {
    width: 14px;
  }

  .response-div {
    display: block;
    text-align: center;
    margin-bottom: 0px;
  }

  .response-div1 {
    display: block;
    /* text-align: center; */
    margin-bottom: 0px;
  }


  .color-7-text {
    margin-left: 0px;
  }

  .briefcase {
    margin-left: 0px;
    color: #0C78F1;
    margin-bottom: 10px;
  }

  .posted-main {
    margin-left: 0px;
  }

  .search-div {
    display: none;
  }



  .copy-right-text {
    color: #ffffff;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.2px;
    line-height: 28.4px;
  }

  /* .navbar-toggler-icon {
    background-color: black;
  } */
  .navbar-collapse {
    margin-top: 30px;
  }

  .navbar-toggler:focus,
  .navbar-toggler:hover {
    outline: none !important;
  }

  .footer-div {
    display: block;
    justify-content: space-between;
  }

  .a-unique-source {
    text-align: center;
    font-weight: 600;
    font-size: 34px;
    line-height: 53.6px;
    letter-spacing: -1.5px;
    color: #001D53;
  }

  .margin-top-number {
    margin-top: 50px;
  }

  .about-time {
    margin-top: 20px;
    font-size: 24px;
  }

  .join-our-community {
    width: 100%;
  }

  .school-impact-big-img {
    margin-top: 40px;
  }

  .our-process-sub h6 {
    text-align: start;
    font-size: 20px;
    font-weight: 600;
    color: #1F1D1D;
    line-height: 28px;
    letter-spacing: -1px;
    margin-bottom: 30px;
  }

  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba(33, 37, 41, 0.75)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 100%;
    display: inline-block;
    height: 1.5em;
    vertical-align: middle;
    width: 1.5em;
  }

  .sign-up-form {
    background-color: #FFFFFF;
    padding: 28px 28px 28px 28px;
    width: 90%;
    margin: auto;
    margin-top: 50px;
    border-radius: 10px;
    border: 1px solid #E9EDF2;
    box-shadow: 4px 8px 16px 0px #2447710F;
  }

  .extended-div-container {
    display: block;
    justify-content: space-between;
  }

  .query-details-container {
    margin-top: 30px;
    padding: 20px 20px;
    border-radius: 20px;
    box-shadow: 4px 8px 32px 0px #2447710F;
  }

  .navbar-bcg {
    padding: 20px 30px !important;
  }

  .query-details-container1 {
    margin-top: 40px;
    padding: 20px 30px;
    border-radius: 20px;
    box-shadow: 4px 8px 32px 0px #2447710F;
    border: 1px solid red;
  }

  .query-description {
    font-size: 16px;
    color: #000000;
    font-weight: 500;
    line-height: 23.4px;
    margin-top: 30px;
  }

  .for-flex {
    display: block;
    justify-content: space-between;
    margin-top: 30px;
  }

  .my-queries-sub {
    color: #50607B;
    font-weight: 500;
    font-size: 18px;
    line-height: 28.6px;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .account-container1 {
    padding: 20px;
    background-color: #ffffff;
    box-shadow: 4px 8px 32px 0px #2447710F;
    border-radius: 20px;
    margin-bottom: 50px;
  }

  .dropdown-menu {
    margin-left: 0px !important;
  }

  .good-greeting {
    font-size: 20px;
  }

  .extended-admin-text {
    font-size: 16px;
  }

  .ReactModal__Content--after-open {
    padding: 0px !important;
  }

  .app-modal-content {
    /* padding: 10px !important; */
  }

  .ReactModal__Content {
    inset: 10px !important;
  }

  .the-background-palace {
    border: 1px solid #E9EDF2;
    border-radius: 20px;
    box-shadow: 4px 8px 32px 0px #2447710F;
    padding: 30px 25px;
    margin-bottom: 50px;
  }

  .input-point {
    font-size: 14px;
  }

  .extended-line {
    margin-top: 20px;
  }

  .pitch-bcg {
    width: 100%;
    margin-top: 20px;
  }

  .input-comment-text-div {
    margin-bottom: 30px;
  }

  /* .extended-line img {
    display: none;
  } */
}


/* Loader container to center the loader on the page */
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* Full viewport height */
}

/* The loader */
.loader {
  border: 8px solid #f3f3f3;
  /* Light gray background */
  border-top: 8px solid #0c78f1;
  /* Blue color for the loader */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

/* Spin animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}






.custom-dropzone-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  margin-bottom: 10px;
  margin-top: 50px;

}

.custom-dropzone {
  width: 100%;
  height: 100%;
  border: 2px dashed #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
}

.preview-image {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.preview-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
}

.remove-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #0C78F1;
  color: white;
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.thumbnail-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
  margin-top: 35px;
  /* Adjust margin as needed */
}

.thumbnail {
  position: relative;
  width: calc(25% - 10px);
  border-radius: 30px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}

.thumbnail.selected {
  border: 3px solid #0C78F1;
  /* Border for selected image */
}

.thumbnail img {
  width: 100%;
  height: auto;
}

.add-more {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(25% - 10px);
  height: 100px;
  /* Adjust height as needed */
  background-color: #F5F9FF;
  border-radius: 20px;
  cursor: pointer;
}

.add-more p {
  margin-bottom: 0px;
}

.custom-dropzone {
  padding: 0px 0px;
  border-radius: 20px;
}

.drop-files-text {
  margin-top: 50px;
  text-align: center;
  font-size: 18px;
  color: #334155;
  font-weight: 600;
}

.drop-files-subtext {
  margin-bottom: 50px;
  text-align: center;
  text-align: center;
  font-size: 16px;
  color: #334155;
  font-weight: 400;
}

.clear-doubt {
  display: flex;
  /* justify-content: space-between; */

  margin-top: 25px;
  width: 100%;
}

.clear-doubt-text {
  color: #64748B;
  font-weight: 400;
  font-size: 14px;
  margin-right: 20px;

}











.document-dropzone-container {
  width: 100%;
  margin-top: 20px;
  padding: 20px;
  border: 2px dashed #ccc;
  border-radius: 10px;
}

.document-dropzone {
  padding: 40px;
  text-align: center;
  cursor: pointer;
  background-color: #f9f9f9;
  position: relative;
}

.upload-button {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.error-message {
  color: red;
  margin-top: 10px;
}

.document-list {
  margin-top: 20px;
}

.document-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  position: relative;
}

.document-info {
  margin-left: 10px;
}

.document-name {
  font-weight: bold;
}

.document-extension {
  font-size: 12px;
  color: #666;
}

.file-remove-btn {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  background: #0c78f1;
  color: white;
  border: none;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  outline: none;
}

.submit-leak {
  border-radius: 30px;
  background-color: #007bff;
  color: #ffffff;
  padding: 15px 35px;
  border: none;
  margin-top: 30px;
}

.submit-leak-container {
  text-align: end;
}

.navbar-toggler {
  border: none !important;
  padding: none !important;
}


.nav-item .active {
  color: #437EF7 !important;
}